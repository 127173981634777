import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RecordComponent } from './record/record.component';

export const routes: Routes = [
  {
    path: '',
    component: RecordComponent,
    canActivate: [AuthGuard]
  }
];
