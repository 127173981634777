import { Component } from '@angular/core';
import { ApiService } from './api.service';
import { RecordComponent } from './record/record.component';
import { PlaybackComponent } from './playback/playback.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TextDisplayComponent } from './text-display/text-display.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RecordComponent, PlaybackComponent, TextDisplayComponent, HttpClientModule, MatProgressSpinnerModule, CommonModule],
  providers: [ApiService, HttpClient],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  audioBlob!: Blob;
  transcription!: string;
  response!: string;
  transcriptionCaption: string = "Input:";
  responseCaption: string = "Answer:";
  isLoading: boolean = false;

  constructor(private apiService: ApiService) { }

  processRecording(audioBlob: Blob) {
    this.isLoading = true;
    this.transcription = '';
    this.response = '';

    this.apiService.transcribeAudio(audioBlob).subscribe({
      next: (transcription) => {
        this.transcription = transcription.text;
        this.apiService.infer(transcription.text).subscribe({
          next: (response) => {
            this.response = response || "Es konnte keine passende Antwort generiert werden.";
            this.apiService.textToSpeech(this.response).subscribe({
              next: (ttsAudioBlob) => {
                this.audioBlob = ttsAudioBlob;
                this.isLoading = false;
              },
              error: (error) => {
                console.error('Text-to-speech error', error);
                this.isLoading = false;
                this.response += "\n(Error generating audio)";
              }
            });
          },
          error: (error) => {
            console.error('Inference error', error);
            this.isLoading = false;
            this.response = "Ein Fehler ist bei der Verarbeitung aufgetreten.";
          }
        });
      },
      error: (error) => {
        console.error('Transcription error', error);
        this.isLoading = false;
        this.transcription = "Ein Fehler ist bei der Transkription aufgetreten.";
      }
    });
  }
}
