import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import WaveSurfer from "wavesurfer.js";

@Component({
  selector: "app-playback",
  templateUrl: "./playback.component.html",
  styleUrls: ["./playback.component.scss"],
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
})
export class PlaybackComponent implements AfterViewInit {
  @ViewChild("waveform") waveformEl!: ElementRef;
  wavesurfer!: WaveSurfer;
  isPlaying: boolean | null = null;

  @Input() set audioBlob(value: Blob | null) {
    if (value) {
      this.isPlaying = false;
      const audioUrl = URL.createObjectURL(value);
      this.wavesurfer.load(audioUrl);
      this.wavesurfer.on("ready", () => {
        this.wavesurfer.play();
      });
      this.wavesurfer.on("pause", () => {
        this.isPlaying = false;
      });
      this.wavesurfer.on("play", () => {
        this.isPlaying = true;
      });
    }
  }

  ngAfterViewInit() {
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformEl.nativeElement,
      waveColor: "rgb(255, 79, 88)",
      progressColor: "rgb(61, 111, 180)",
    });
  }

  playPause() {
    this.wavesurfer.playPause();
  }
}
