import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AudioRecordingService } from '../recording.service';

@Component({
  selector: 'app-record',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  providers: [AudioRecordingService],
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
})
export class RecordComponent {
  isRecording = false;
  @Output() recorded = new EventEmitter<Blob>();

  constructor(private recordingService: AudioRecordingService) {
  }

  toggleRecording() {
    this.isRecording ? this.stopRecording() : this.startRecording();
  }

  async startRecording() {
    this.recordingService.startRecording();
    this.isRecording = true;
  }

  async stopRecording() {
    let blob = await this.recordingService.stopRecording();
    console.log("RECORDED BLOB: ", blob);
    this.recorded.emit(blob);
    this.isRecording = false;
  }
}