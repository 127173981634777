import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseApiUrl = '/api/';

  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService
  ) {}

  private getAuthHeaders(): Observable<HttpHeaders> {
    return from(this.keycloak.getToken()).pipe(
      mergeMap(token => {
        const headers = new HttpHeaders()
          .set('Authorization', `Bearer ${token}`)
        return from([headers]);
      })
    );
  }
  
  transcribeAudio(audioBlob: Blob): Observable<any> {
    console.log("SENT BLOB: ", audioBlob);
    const formData = new FormData();
    formData.append('model', "whisper-1");
    formData.append('language', "en");
    formData.append('file', audioBlob);

    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post(this.baseApiUrl + "transcribeAudio", formData, { headers }))
    );
  }

  infer(input: string): Observable<any> {
    return this.getAuthHeaders().pipe(
      mergeMap(headers => this.http.post(this.baseApiUrl + "availability", { input: input }, { headers }))
    );
  }

  textToSpeech(text: string): Observable<Blob> {
    const payload = {
      model: "tts-1",
      input: text,
      voice: "nova"
    };

    return this.getAuthHeaders().pipe(
      mergeMap(headers => 
        this.http.post(this.baseApiUrl + "textToSpeech", payload, { headers, responseType: 'blob' })
      )
    );
  }
}
