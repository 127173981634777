import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-text-display',
  standalone: true,
  imports: [MatCardModule, CommonModule],
  templateUrl: './text-display.component.html',
  styleUrls: ['./text-display.component.scss']
})
export class TextDisplayComponent {
  @Input() text!: string;
  @Input() caption!: string;
}
