<button
  [class.hidden]="isPlaying === null"
  mat-icon-button
  color="secondary"
  (click)="playPause()"
>
  <mat-icon style="font-size: 28px">
    {{ isPlaying ? "pause" : "play_arrow" }}
  </mat-icon>
</button>

<div #waveform class="waveform"></div>
