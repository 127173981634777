import { ApplicationConfig, isDevMode, APP_INITIALIZER } from '@angular/core';
import { provideRouter } from '@angular/router';
import { KeycloakService, KeycloakBearerInterceptor, KeycloakAngularModule } from 'keycloak-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://login.thinktecture.com/auth/',
        realm: 'tt-internal',
        clientId: 'talk-to-tt-client',
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
        enableLogging: false,
      },
      bearerExcludedUrls: [],
      loadUserProfileAtStartUp: false,
    }).catch(error => {
      console.error('Error initializing Keycloak', error);
      // TODO: We might want to redirect to an error page or show a user-friendly message
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },
    {
      provide: KeycloakAngularModule,
      useValue: {}
    },
    {
      provide: KeycloakService,
      useClass: KeycloakService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ]
};
